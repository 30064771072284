import MoreVertIcon from '@mui/icons-material/MoreVert';
import RestoreIcon from '@mui/icons-material/Restore';
import { Link, ListItemIcon, ListItemText, Menu, MenuList } from '@mui/material';
import IconButton from '@mui/material/IconButton';
import MenuItem from '@mui/material/MenuItem';
import CatalogApiService from 'api/CatalogApiService';
import useApi from 'hooks/useApi';
import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { Link as RouterLink } from 'react-router-dom';
import { loadCatalogArchivedAction, loadCatalogsAction } from 'store/actions/appActions';
import { useSelector } from 'store/hooks';
import { useAppState } from 'store/Provider';
import Catalog from 'types/entities/Catalog';
import Order from 'types/entities/Order';
import User from 'types/entities/User';
import { ArchiveIcon, DuplicateIcon, EditIcon, DeleteIcon } from './CustomIcons';
import Loader from './Loader';
import { openDialogAction } from 'store/actions/dialogsActions';
import CatalogDialogDelete from 'features/Catalogs/CatalogsDialogs/CatalogDialogDelete';
import { setSnackbarAction } from 'store/actions/snackbarActions';

interface Props {
  activeItem: Catalog | Order | User;
}

const MenuActions: React.FC<Props> = ({ activeItem }) => {
  const [t] = useTranslation();
  const { dispatch } = useAppState();
  const appState = useSelector((state) => state.app);
  const loadings = useSelector((state) => state.loadings);
  const { makeCall } = useApi();
  const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null);
  const [showDeleteDialog, setShowDeleteDialog] = React.useState(false);
  const open = Boolean(anchorEl);
  const [loading, setLoading] = useState(false);

  const handleClick = (event: React.MouseEvent<HTMLElement>) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const duplicateCatalog = async () => {
    await makeCall(
      CatalogApiService.duplicate(activeItem.id),
      'Failed to duplicate catalog',
      `catalog_duplicate_${activeItem.id}`
    );
    if (appState.customer) {
      const newCatalogs = await makeCall(
        CatalogApiService.fetchList([
          'reference',
          'updated_at',
          'validity_from',
          'validity_to',
          'nb_workunits',
          'is_archived',
          'id',
          'customer_id',
        ]),
        'Failed to retrieve catalogs'
      );
      dispatch(loadCatalogsAction(newCatalogs));
      const archivedCatalogs = await makeCall(
        CatalogApiService.fetchList(
          [
            'reference',
            'updated_at',
            'validity_from',
            'validity_to',
            'nb_workunits',
            'is_archived',
            'id',
            'customer_id',
          ],
          true
        ),
        'Failed to retrieve catalogs'
      );
      dispatch(loadCatalogArchivedAction(archivedCatalogs));
    }
    handleClose();
  };

  const restoreCatalog = async () => {
    await makeCall(
      CatalogApiService.delete(activeItem.id || appState.catalog?.id || activeItem.id),
      'Failed to restore catalog',
      'catalog'
    );

    dispatch(loadCatalogArchivedAction(appState.archivedCatalogs?.filter((catalog) => catalog?.id !== activeItem.id)));
    dispatch(
      loadCatalogsAction([...(appState.catalogs as Catalog[]), { ...activeItem, is_archived: false } as Catalog])
    );
    handleClose();
  };
  const deleteCatalog = async (action: 'delete' | 'archive') => {
    const response = await makeCall(
      CatalogApiService.delete(activeItem?.id || appState.catalog?.id || activeItem?.id, action),
      'Failed to delete catalog',
      'catalog'
    );
    if (response?.error) {
      console.log('Error Message from API:', response.message);
      return; // Exit early if an error occurs
    }
    console.log('API Response Message1:', response.message);
    if (action === 'delete') {
      const updatedArchivedCatalogs = appState.archivedCatalogs?.filter((catalog) => catalog?.id !== activeItem?.id);
      dispatch(loadCatalogArchivedAction(updatedArchivedCatalogs));
      dispatch(setSnackbarAction({ message: `The catalog is deleted successfully.`, open: true, severity: 'success' }));
    } else if (action === 'archive') {
      const updatedCatalogs = appState.catalogs?.filter((catalog) => catalog?.id !== activeItem?.id);
      dispatch(loadCatalogsAction(updatedCatalogs));
      dispatch(
        loadCatalogArchivedAction([
          ...(appState.archivedCatalogs as Catalog[]),
          { ...activeItem, is_archived: true } as Catalog,
        ])
      );
      dispatch(
        setSnackbarAction({ message: `The catalog is archived successfully.`, open: true, severity: 'success' })
      );
    }
    handleClose();
  };

  const openDeleteDialog = () => {
    setShowDeleteDialog(true);
    handleClose(); // Close the menu
  };

  const handleDeleteDialogClose = () => {
    setShowDeleteDialog(false);
  };

  const handleDeleteConfirm = async () => {
    try {
      setLoading(true);
      await deleteCatalog('delete');
      setShowDeleteDialog(false);
    } catch (error) {
      console.error('Error deleting catalog:', error);
    } finally {
      setLoading(false);
    }
  };

  return (
    <>
      <IconButton
        aria-label="settings"
        aria-controls="long-menu"
        aria-haspopup="true"
        onClick={handleClick}
        color="primary"
        size="large"
      >
        <MoreVertIcon />
      </IconButton>
      <Menu id="long-menu" anchorEl={anchorEl} keepMounted open={open} onClose={handleClose}>
        <MenuList>
          <Link
            component={RouterLink}
            to={{
              pathname: `/${appState.customer?.slug}/catalogs/${activeItem.id || appState.catalog?.id}`,
            }}
            underline="none"
            color="textPrimary"
          >
            <MenuItem onClick={handleClose} key={`menuitem_edit${Math.random() * 1100000}`}>
              <ListItemIcon>
                <EditIcon fontSize="small" sx={{ color: 'text.primary' }} />
              </ListItemIcon>
              <ListItemText>{t('Modify')}</ListItemText>
            </MenuItem>
          </Link>
          <MenuItem
            disabled={loadings[`catalog_duplicate_${activeItem.id}`] > 0}
            onClick={duplicateCatalog}
            key={`menuitem_duplicate${Math.random() * 1100000}`}
          >
            <ListItemIcon>
              {loadings[`catalog_duplicate_${activeItem.id}`] > 0 && <Loader size={10} />}
              {(loadings[`catalog_duplicate_${activeItem.id}`] === 0 ||
                !loadings[`catalog_duplicate_${activeItem.id}`]) && (
                <>
                  <DuplicateIcon fontSize="small" sx={{ color: 'text.primary' }} />{' '}
                </>
              )}
            </ListItemIcon>
            <ListItemText>{t('Duplicate')}</ListItemText>
          </MenuItem>
          {!activeItem?.is_archived && (
            <MenuItem
              onClick={() => deleteCatalog('archive')}
              key={`menuitem_trash${Math.random() * 1100000}`}
              disabled={!!loadings.catalog}
            >
              {loadings.catalog ? (
                <Loader size={10} />
              ) : (
                <>
                  <ListItemIcon>
                    <ArchiveIcon fontSize="small" sx={{ color: 'text.primary' }} />
                  </ListItemIcon>
                  <ListItemText>{t('archive')}</ListItemText>
                </>
              )}
            </MenuItem>
          )}
          {activeItem?.is_archived && (
            <MenuItem
              // onClick={() => deleteCatalog('delete')}
              onClick={() => openDeleteDialog()}
              key={`menuitem_trash${Math.random() * 1100000}`}
              disabled={!!loadings.catalog}
            >
              {loadings.catalog ? (
                <Loader size={10} />
              ) : (
                <>
                  <ListItemIcon>
                    <DeleteIcon fontSize="small" sx={{ color: 'text.primary' }} />
                  </ListItemIcon>
                  <ListItemText>{t('delete')}</ListItemText>
                </>
              )}
            </MenuItem>
          )}
          {activeItem?.is_archived && (
            <MenuItem
              onClick={restoreCatalog}
              key={`menuitem_trash${Math.random() * 1100000}`}
              disabled={!!loadings.catalog}
            >
              {loadings.catalog ? (
                <Loader size={10} />
              ) : (
                <>
                  <ListItemIcon>
                    <RestoreIcon fontSize="small" sx={{ color: 'text.primary' }} />
                  </ListItemIcon>
                  <ListItemText>{t('Restore')}</ListItemText>
                </>
              )}
            </MenuItem>
          )}
        </MenuList>
      </Menu>
      <CatalogDialogDelete
        open={showDeleteDialog}
        onClose={handleDeleteDialogClose}
        onConfirm={handleDeleteConfirm}
        loading={loading}
      />
    </>
  );
};

export default MenuActions;
