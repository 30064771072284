import { Box, Button, Grid, Typography } from '@mui/material';
import ResourceAPI from 'api/ResourceAPI';
import UserApiService from 'api/UserApiService';
import { Layout, Loader } from 'components';
import { PropertyNameOptions, SelectTypeOptions } from 'components/Select';
import useApi from 'hooks/useApi';
import moment, { Moment } from 'moment';
import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { RouteComponentProps, useHistory } from 'react-router-dom';
import { setSnackbarAction } from 'store/actions/snackbarActions';
import { useSelector } from 'store/hooks';
import { useAppState } from 'store/Provider';
import Order from 'types/entities/Order';
import TMOrderScope from 'types/entities/TMOrderScope';
import User from 'types/entities/User';
import Scope from 'types/entities/Scope';
import TimeMaterialOrderForm from '../TnMOrderForm/TnMOrderForm';
import ScopeApiService from 'api/ScopeApiService';
import { OrderType } from 'types/enums/OrderType';
import { OrdersTabState } from 'types/enums/OrdersTabState';
import { updateIds } from 'helpers/orderhelper';

interface RouteParams {
  catalog_id: string;
}

interface HasId {
  id: number;
}

const TnMCreationOrder: React.FC<RouteComponentProps<RouteParams>> = ({ match }) => {
  const { dispatch } = useAppState();
  const appState = useSelector((state) => state.app);
  const [t] = useTranslation();
  const [newOrder, setNewOrder] = useState<Partial<Order>>({
    start_date: moment().format('YYYY-MM-DD'), // moment().toISOString(),
    end_date: moment().format('YYYY-MM-DD'), // moment().toISOString(),
    is_sign_mandatory: true,
    client_connection: appState.customer?.client_connection,
  });
  const history = useHistory();
  const { makeCall } = useApi();
  const customer = useSelector((state) => state.app.customer);

  const handleChange = (e: React.FormEvent<HTMLInputElement | HTMLSelectElement | HTMLTextAreaElement>) => {
    e.preventDefault();
    return setNewOrder({ ...newOrder, [e.currentTarget.name]: e.currentTarget.value });
  };

  const handleSelectClient = (client: User | null | undefined) => {
    if (client) return setNewOrder({ ...newOrder, client, client_id: client.id });
    return setNewOrder({ ...newOrder, client: undefined, client_id: undefined });
  };
  const handleSelectRepresentative = (representative: User | null | undefined) => {
    // console.log(`delegue_id: ${representative?.id}`);
    if (representative) return setNewOrder({ ...newOrder, delegue: representative, delegue_id: representative.id });
    return setNewOrder({ ...newOrder, delegue: undefined, delegue_id: undefined });
  };
  const handleSelectOptions = (e: SelectTypeOptions | null, property: PropertyNameOptions) => {
    if (e && property === 'affair') return setNewOrder({ ...newOrder, [`${property}`]: e, [`${property}_id`]: e.id });
    return setNewOrder({ ...newOrder, affair: undefined, affair_id: undefined });
  };
  const handleDateChange = (name: string, date: Moment | null) => {
    setNewOrder({ ...newOrder, [name]: date ? moment(date).format('YYYY-MM-DD') : null });
  };

  const [isDialogOpen, setIsDialogOpen] = useState(false);
  const [isInValidation, setIsInValidation] = useState(false);
  const [isMissingInputs, setIsMissingInputs] = useState(false);
  const [isDraftMissingInputs, setIsDraftMissingInputs] = useState(false);
  const [creators, setCreators] = useState<User[]>([]);
  const [scopes, setScopes] = useState<Scope[]>([]);
  const [selectedScopes, setSelectedScopes] = useState<Scope[]>([]);
  const [archivedScopes, setArchivedScopes] = useState<Scope[]>([]);
  const [clients, setClients] = useState<User[]>([]);
  const [consultants, setConsultants] = useState<User[]>([]);
  const [deliveryManagers, setDeliveryManagers] = useState<User[]>([]);
  const [archivedDeliveryManagers, setArchivedDeliveryManagers] = useState<User[]>([]);
  const [archivedConsultants, setArchivedConsultants] = useState<User[]>([]);
  const [selectedConsultants, setSelectedConsultants] = useState<User[]>([]);
  const [selectedDeliveryManager, setSelectedDeliveryManager] = useState<User[]>([]);
  type ActionType = 'save_as_draft' | 'validate';

  useEffect(() => {
    const getScopes = async () => {
      if (customer?.id) {
        const scopesRetrieved = await ScopeApiService.fetchByCustomerId(customer.id);
        setScopes(scopesRetrieved);
      }
    };
    getScopes();
  }, []);

  useEffect(() => {
    const getCreators = async () => {
      const creatorsFetched = await UserApiService.fetchPossiblesDelegues();
      setCreators(creatorsFetched);
    };
    getCreators();
  }, []);

  const isDateValid = (startDate: string | undefined, endDate: string | undefined): boolean => {
    if (!startDate || !endDate) {
      return false;
    }
    const start = new Date(startDate);
    const end = new Date(endDate);
    start.setHours(0, 0, 0, 0);
    end.setHours(0, 0, 0, 0);

    if (Number.isNaN(start.getTime()) || Number.isNaN(end.getTime())) {
      return false;
    }
    return end >= start;
    // const flag = moment(endDate, 'DD/MM/YYYY').isSameOrAfter(moment(startDate, 'DD/MM/YYYY'));
    // console.log('flag: ', flag);
    // console.log('endDate: ', endDate);

    // return moment(endDate).isSameOrAfter(moment(startDate));
    // return moment(endDate, 'DD/MM/YYYY').isSameOrAfter(moment(startDate, 'DD/MM/YYYY'));
  };

  useEffect(() => {
    const missingInputs =
      !newOrder?.affair_id ||
      !newOrder?.name_spec ||
      !isDateValid(newOrder?.start_date, newOrder?.end_date) ||
      selectedScopes.length === 0 ||
      selectedDeliveryManager.length === 0 ||
      selectedConsultants.length === 0 ||
      !newOrder?.client_id;

    //  console.log('missingInputs : ', missingInputs);
    setIsMissingInputs(missingInputs);

    // const hasDraftMissingInputs =
    //   !newOrder?.affair_id &&
    //   (newOrder?.start_date && newOrder?.end_date ? !isDateValid(newOrder?.start_date, newOrder?.end_date) : false);
    const hasDraftMissingInputs =
      !newOrder?.affair_id || !isDateValid(newOrder?.start_date, newOrder?.end_date) || !newOrder?.name_spec;

    //  console.log('hasDraftMissingInputs : ', hasDraftMissingInputs);
    setIsDraftMissingInputs(hasDraftMissingInputs);
  }, [newOrder, selectedScopes, selectedDeliveryManager, selectedConsultants]);

  const handleSubmitNewOrder = async (actionType: ActionType) => {
    setIsInValidation(true);
    const { affair, client, ...orderToSave } = newOrder as Order;
    const { ids: accountable_ids, archived: is_accountable_archived } = updateIds(
      selectedDeliveryManager,
      archivedDeliveryManagers
    );
    const { ids: consultant_ids, archived: is_consultant_archived } = updateIds(
      selectedConsultants,
      archivedConsultants
    );
    const { ids: scope_ids, archived: is_scope_archived } = updateIds(selectedScopes, archivedScopes);

    const client_ids = client?.id ? [client?.id] : null;
    const is_client_archived = client_ids !== null ? [false] : [];

    const tmOrderScope: TMOrderScope = {
      id: 0, // This will be generated by the backend
      accountable_ids,
      consultant_ids,
      client_ids,
      scope_ids,
      order_id: orderToSave.id,
      is_scope_archived,
      is_accountable_archived,
      is_consultant_archived,
      is_client_archived,
    };

    try {
      const orderStatus =
        actionType === 'save_as_draft' ? OrdersTabState.MATERIAL_DRAFT : OrdersTabState.MATERIAL_PRODUCTION;
      const res = await ResourceAPI.post('orders', {
        ...orderToSave,
        client_id: newOrder?.client_id,
        customer_id: appState.customer?.id,
        affair_name: affair?.name || null,
        affair_otp: affair?.otp || null,
        affair_id: affair?.id || null,
        creator_id: appState.user?.id,
        ref_order: newOrder?.ref_order || null,
        order_type_id: OrderType.TIMEANDMATERIAL,
        status: orderStatus,
        tm_order_scope: tmOrderScope,
        catalog_id: null,
        start_date: moment(newOrder?.start_date).format('YYYY-MM-DD'),
        end_date: moment(newOrder?.end_date).format('YYYY-MM-DD'),
      });
      if (res) {
        if (orderStatus === OrdersTabState.MATERIAL_DRAFT)
          history.push(`/${appState.customer?.slug}/orders/production?status=material_drafts`);
        else if (orderStatus === OrdersTabState.MATERIAL_PRODUCTION)
          history.push(`/${appState.customer?.slug}/orders/production?status=material_production`);
      }
    } catch (error) {
      const result = (error as Error).message;

      if (result) {
        dispatch(
          setSnackbarAction({
            message: `Order saving failed : ${result}`,
            open: true,
            severity: 'error',
          })
        );
      } else {
        dispatch(
          setSnackbarAction({
            message: `Order saving failed : ${error}`,
            open: true,
            severity: 'error',
          })
        );
      }
    } finally {
      setIsInValidation(false);
    }
  };

  const handleClose = () => {
    history.push(`/${appState.customer?.slug}/orders/production?status=material_drafts`);
  };

  const handleSwitchSign = (value: boolean) => {
    setNewOrder({ ...newOrder, is_sign_mandatory: value });
  };

  const handleClientConnectionSwitch = (value: boolean) => {
    setNewOrder({ ...newOrder, client_connection: value });
  };

  useEffect(() => {
    const getClients = async () => {
      if (appState.customer) {
        const res = await makeCall(
          UserApiService.getClientsOnCustomer(appState.customer?.id, { is_archived: 0, size: '-1' }),
          'Failed to retrieve clients'
        );
        setClients(res);
      }
    };

    getClients();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [appState.customer]);

  useEffect(() => {
    const getConsultants = async () => {
      if (appState.customer && appState.customer.id) {
        const res = await makeCall(UserApiService.fetchPossiblesConsultants(), 'Unable to retrieve consultants');
        setConsultants(res);
      }
    };
    getConsultants();
  }, [appState.customer]);

  useEffect(() => {
    const getDeliveryManagers = async () => {
      if (appState.customer && appState.customer.id) {
        const res = await makeCall(
          UserApiService.fetchPossiblesDeliverableTeamLeaders(),
          'Unable to retrieve delivery managers'
        );
        setDeliveryManagers(res);
      }
    };
    getDeliveryManagers();
  }, [appState.customer]);

  const handleScopeAdded = (newScope: Scope) => {
    const scopeWithArchiveStatus = { ...newScope, is_archived: false };
    setScopes((prevScopes) => [...prevScopes, scopeWithArchiveStatus]);
  };

  const handleScopeArchived = (updatedScope: Scope) => {
    setScopes((prevScopes) =>
      prevScopes.map((scope) => (scope.id === updatedScope.id ? { ...scope, is_archived: true } : scope))
    );
    setArchivedScopes((prevArchivedScopes) => [...prevArchivedScopes, updatedScope]);
  };

  const handleScopesChange = (newSelectedScopes: Scope[]) => {
    setSelectedScopes(newSelectedScopes);
  };

  const handleConsultantChange = (newSelectedConsultants: User[], reason?: string) => {
    setSelectedConsultants(newSelectedConsultants);
  };

  const handleDeliveryManagerChange = (newSelectedDeliveryManager: User[], reason?: string) => {
    // console.log('handleDeliveryManagerChange:', newSelectedDeliveryManager);
    setSelectedDeliveryManager(newSelectedDeliveryManager);
  };

  const handleDeliveryManagerArchived = (updatedUser: User) => {
    // console.log('handleDeliveryManagerArchived:', updatedUser);
    setArchivedDeliveryManagers((prevArchivedDeliveryManager) => [...prevArchivedDeliveryManager, updatedUser]);
  };

  const handleConsultantArchived = (updatedUser: User) => {
    setArchivedConsultants((prevArchiveConsultant) => [...prevArchiveConsultant, updatedUser]);
  };

  return (
    <Layout name={t('Create_time_material_order_tm')} path="Create_time_material_order_tm">
      <TimeMaterialOrderForm
        handleChange={handleChange}
        handleSelectClient={handleSelectClient}
        handleSelectRepresentative={handleSelectRepresentative}
        handleSelectOptions={handleSelectOptions}
        handleDateChange={handleDateChange}
        handleSwitchSign={handleSwitchSign}
        handleClientConnectionSwitch={handleClientConnectionSwitch}
        order={newOrder}
        creators={creators}
        clients={clients}
        deliveryManagers={deliveryManagers}
        consultants={consultants}
        scopes={scopes}
        onScopeAdded={handleScopeAdded}
        onScopeArchived={handleScopeArchived}
        handleScopesChange={handleScopesChange}
        selectedScopes={selectedScopes}
        onDeliveryManagerArchived={handleDeliveryManagerArchived}
        onConsultantArchived={handleConsultantArchived}
        handleConsultantChange={handleConsultantChange}
        handleDeliveryManagerChange={handleDeliveryManagerChange}
        selectedConsultants={selectedConsultants}
        selectedDeliveryManager={selectedDeliveryManager}
        variant="filled"
      />
      <Box sx={{ m: 'auto -30px -30px', p: '30px', backgroundColor: '#FFFFFF', boxShadow: '0px 3px 16px #B2BCD571' }}>
        <Grid container spacing={4} alignItems="center" justifyContent="space-between">
          <Grid item xs={12} md={4} lg={2}>
            <Button onClick={handleClose} disabled={false} color="success" variant="contained">
              {isInValidation ? <Loader size={20} className="white-loader" /> : t('Cancel_tm')}
            </Button>
          </Grid>

          <Grid item xs={12} md={2} lg={2} sx={{ display: 'flex', justifyContent: 'flex-end' }}>
            <Grid item>
              <Button
                onClick={() => handleSubmitNewOrder('save_as_draft')}
                disabled={isInValidation || isDraftMissingInputs}
                color="success"
                variant="contained"
                sx={{ width: 150, mr: 2 }}
              >
                {isInValidation ? <Loader size={20} className="white-loader" /> : t('Save_as_draft_tm')}
              </Button>
            </Grid>

            <Grid item>
              <Button
                onClick={() => handleSubmitNewOrder('validate')}
                disabled={isInValidation || isMissingInputs}
                color="success"
                variant="contained"
              >
                {isInValidation ? <Loader size={20} className="white-loader" /> : t('Validate_tm')}
              </Button>
            </Grid>
          </Grid>
        </Grid>
      </Box>
    </Layout>
  );
};

export default TnMCreationOrder;
