import React, { SyntheticEvent, useCallback, useEffect, useState } from 'react';
import { Card, CardMedia, CardContent, Typography, Grid, Tooltip, Autocomplete, TextField } from '@mui/material';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'store/hooks';
import VideoTag from '../VideoTag/VideoTag';
import useHelpMenuService from 'features/HelpMenu/hooks/useHelpMenuService';
import Categories from 'types/entities/Categories';
import Loader from 'components/Loader';
import EmptyState from 'components/EmptyState';
import './VideoThumbnail.scss';
import HelpMenuListVideos from 'types/entities/HelpMenuVideos';

const VideoThumbnail: React.FC = () => {
  const [t] = useTranslation();
  const loadings = useSelector((state) => state.loadings);
  const state = useSelector((state) => state.helpMenu);
  const [videos, setVideos] = useState<HelpMenuListVideos[]>();
  const [categories, setCategories] = useState<Categories[]>();

  const { fetchVideo } = useHelpMenuService();

  const getVideo = useCallback(async (videoId: number) => {
    const data = await fetchVideo(videoId);
    window.open(data, '_blank');
  }, []);

  useEffect(() => {
    setVideos(state.datas ?? []);
    setCategories(state?.categories ?? []);
  }, [state?.datas]);

  const handleOptionChange = async (event: SyntheticEvent<Element, Event>, value: Categories[]) => {
    if (value.length > 0) {
      const currentDatafile = state?.datas?.filter((item) =>
        item.categories.some((category) => value.some((val) => val.name === category.name))
      );
      setVideos(currentDatafile ?? []);
    } else {
      setVideos(state?.datas ?? []);
    }
  };

  return loadings?.videothumbnail ? (
    <Loader className="loader_container_thumbnail" />
  ) : (
    <>
      {videos && videos?.length === 0 && (
        <Grid item xs={12} xl="auto">
          <EmptyState title={t('no_video_found')} />
        </Grid>
      )}

      <Grid container display="flex" justifyContent="flex-end">
        {videos && videos?.length !== 0 && (
          <Autocomplete
            noOptionsText="No categories found"
            style={{ width: '35%', marginBottom: '10px' }}
            multiple
            id="tags-outlined"
            onChange={(e, value) => handleOptionChange(e, value)}
            options={categories ?? []}
            getOptionLabel={(option) => option?.name}
            filterSelectedOptions
            renderInput={(params) => <TextField {...params} label="Filter Categories" placeholder="Categories" />}
          />
        )}
      </Grid>
      <Grid container flexWrap="wrap" gap={2}>
        {videos?.map((video, index) => (
          <Grid item xs="auto" lg="auto" xl="auto" sx={{ overflow: 'hidden', backgroundColor: '#fff' }}>
            <Card
              sx={{
                width: { xs: 400, sm: 350, md: 350, xl: 400 },
                cursor: 'pointer',
                height: '100%',
              }}
            >
              <CardMedia
                height={200}
                component="img"
                src={video.presignedUrl || ''}
                alt={`Thumbnail for ${video.title}`}
                onClick={() => getVideo(video?.id)}
                sx={{ objectFit: 'fill' }}
              />
              <CardContent>
                <Typography variant="h6">{video.title}</Typography>
                <Tooltip
                  title={video.description ?? ''}
                  componentsProps={{
                    tooltip: {
                      sx: {
                        boxShadow: '0px 4px 20px rgba(0, 0, 0, 0.25)',
                      },
                    },
                  }}
                  arrow
                >
                  <Typography
                    variant="body2"
                    sx={{
                      height: '48px', // Same fixed height for two lines
                      overflow: 'hidden',
                      display: '-webkit-box',
                      WebkitLineClamp: 2, // Limits the text to 2 lines
                      WebkitBoxOrient: 'vertical',
                      minWidth: '310px',
                    }}
                  >
                    {(video.description ?? '').length <= 80
                      ? video?.description
                      : `${video?.description?.slice(0, 150)}...`}
                  </Typography>
                </Tooltip>
                <Typography variant="body2" color="textSecondary">
                  <VideoTag items={video?.categories} maxVisible={2} />
                </Typography>
              </CardContent>
            </Card>
          </Grid>
        ))}
      </Grid>
    </>
  );
};

export default VideoThumbnail;
